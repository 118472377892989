import React from 'react';
import styles from './style.module.scss';
import Container from '../Container/Container';
import cn from 'classnames'

const FooterSocials: React.FC<{fixedBottom?: boolean}> = ({fixedBottom = true}) => {
  return (
    <footer className={cn(styles.footer, {[styles.bottomFixed]: fixedBottom})}>
      <Container notMt sx={!fixedBottom ? {width: '100%'} : {}}>
        <a target='_blank' href='https://vk.com/codenrock'><img src='/assets/icons/socials/VK.svg' alt='VK' /></a>
        <a target='_blank' href='https://www.youtube.com/c/Codenrock'><img src='/assets/icons/socials/YouTube.svg' alt='YouTube' /></a>
        <a target='_blank' href='https://t.me/codenrock'><img src='/assets/icons/socials/telegram.svg' alt='Telegram'/></a>
      </Container>
    </footer>
  );
};

export default FooterSocials;
