import React, {useEffect, useState} from 'react';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import styles from './style.module.scss'
import {PATH_GAME} from '../../helpers/urlList';
import Placeholder from '../../components/Placeholder/Placeholder';
import Button from '../../components/Button/Button';
import {useNavigate} from 'react-router-dom';
import {ISolution} from 'src/reducers/GamePage.reducer';
import {IAnswer, IFormInfoData} from "src/reducers/FormPage.reducer";
import {IQuestion} from "src/reducers/FormPage.reducer";

const CONTEST_ID = process.env.REACT_APP_CONTEST_ID;
const FOR_2_BOWLS_NOMINATION_ID = 1001;
const FOR_2_BOWLS_TASK_ID = 2433;

interface IFormPage {
    representation?: string;
    sendAnswer: (
        contest_id: number, nomination_id: number, task_id: number, answer: number,
        onSuccess?: (data: ISolution) => void) => any;
    sendForm: (
        formData: { answers: { question_id: number, value: string | number }[] },
        onSuccess: () => void) => void;
    getForm: (
        onSuccess?: (data: IFormInfoData) => void) => void
}

export const FormPage: React.FC<IFormPage> = ({
                                                  representation = 'PAGE',
                                                  sendForm,
                                                  sendAnswer,
                                                  getForm,
                                              }: IFormPage) => {
    const contest_id = CONTEST_ID ? Number(CONTEST_ID) : -1;
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([] as IQuestion[]);

    useEffect(() => {
        getForm((data) => {
            setQuestions(data.questions.map(question => {
                question.answer = '';
                return question;
            }));
        })
    }, [])

    const onHandleForm = (e: React.FormEvent) => {
        e.preventDefault();
        sendForm({
            answers: questions.map(question => {
                return {question_id: question.id, value: question.answer}
            })
        }, () => {
            sendAnswer(contest_id, FOR_2_BOWLS_NOMINATION_ID, FOR_2_BOWLS_TASK_ID, 0, () => {
                navigate(PATH_GAME);
            })
        })
    };

    const writeAnswer = (question: IQuestion, value: string) => {
        setQuestions((qs) => qs?.map((q) => q.id === question.id ? {...q, answer: value} : q))
    }

    return (
        <MainLayout verticalAlign={"flex-start"} headerBtnTo={PATH_GAME} sx={representation === 'DIALOG' ? {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        } : false}>
            <div className={styles.pageWrapper}>
                <h1>Заполните анкету, <br/> и получите 2 бонусных балла!</h1>
                <form onSubmit={onHandleForm}>
                    {questions.map((question: IQuestion, index: number) => {
                        return <Placeholder key={question.id}
                                            name={`question_${question.id}`}
                                            type={'text'}
                                            required={question.required}
                                            value={question.answer}
                                            onChange={(e) => writeAnswer(question, e.target.value)}
                                            label={question.label}/>
                    })}
                    <Button variant={'secondary'} sx={{fontSize: '24px', marginTop: '2rem'}}>Отправить</Button>
                </form>
            </div>
        </MainLayout>
    );
};

export default FormPage;
