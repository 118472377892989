import {setToken, setUserData} from 'actions/user/User.actions';
import {RestAPI, Status} from 'pkg/tulula/auth/api';
import {AnyAction} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import clientApi from '../../helpers/clientApi';
import {
    AUTH_TYPES,
    signInWithEmailFunc,
    signUpWithEmailFunc,
    confirmEmailFunc,
    getProfileFunc,
    resendFunc, logoutFunc
} from './auth.types';

export const signInWithEmail: signInWithEmailFunc =
    (rest: RestAPI, email: string, password: string, rememberMe: boolean): ThunkAction<void, {}, {}, AnyAction> =>
        async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
            try {
                dispatch({
                    type: AUTH_TYPES.SIGN_IN_REQUEST,
                });
                const response = await rest.signIn({email, password, rememberMe});
                if (response.status === Status.Success) {
                    if (response.token) {
                        dispatch(setToken(response.token));
                    }
                    const tokenResponse = await clientApi.get(`auth/me`, {withCredentials: true});
                    if (tokenResponse.data.user) {
                        dispatch(setUserData(tokenResponse.data.user));
                    }
                    dispatch({
                        type: AUTH_TYPES.SIGN_IN_SUCCESS,
                        payload: {...response},
                    });
                } else {
                    dispatch({
                        type: AUTH_TYPES.SIGN_IN_FAILURE,
                        payload: {...response},
                    });
                }
            } catch (e: any) {
                dispatch({
                    type: AUTH_TYPES.SIGN_IN_FAILURE,
                    error: 'Something went wrong',
                });
            }
        };

export const signUpWithEmail: signUpWithEmailFunc =
    (
        rest: RestAPI,
        email: string,
        password: string,
        name: string,
        surname: string,
        teamID?: string
    ): ThunkAction<void, {}, {}, AnyAction> =>
        async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
            const subscribe = '0'; // don't subscribe users in games to our news
            try {
                dispatch({
                    type: AUTH_TYPES.SIGN_UP_REQUEST,
                });
                const response = await rest.signUp({email, password, name, surname, subscribe, teamID});
                if (response.status === Status.Success) {
                    if (response.token) {
                        dispatch(setToken(response.token));
                    }
                    dispatch({
                        type: AUTH_TYPES.SIGN_UP_SUCCESS,
                        payload: {...response},
                    });
                } else {
                    dispatch({
                        type: AUTH_TYPES.SIGN_UP_FAILURE,
                        payload: {...response},
                    });
                }
            } catch (e: any) {
                dispatch({
                    type: AUTH_TYPES.SIGN_UP_FAILURE,
                    error: 'Something went wrong',
                });
            }
        };
export const confirmEmail: confirmEmailFunc = (rest: RestAPI, code: string): ThunkAction<void, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch({
                type: AUTH_TYPES.CONFIRM_REQUEST,
            });
            const response = await rest.confirm({code});
            if (response.status === Status.Success) {
                dispatch({
                    type: AUTH_TYPES.CONFIRM_SUCCESS,
                    payload: {...response},
                });
                if (response.user) {
                    dispatch(setUserData(response.user));
                }
            } else {
                dispatch({
                    type: AUTH_TYPES.CONFIRM_FAILURE,
                    payload: {...response},
                });
            }
        } catch (e: any) {
            dispatch({
                type: AUTH_TYPES.CONFIRM_FAILURE,
                error: 'Something went wrong',
            });
        }
    };

export const resend: resendFunc = (email: string, rest: RestAPI): ThunkAction<void, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch({
                type: AUTH_TYPES.RESEND_REQUEST,
            });
            const response = await rest.resendEmail({email});
            if (response.status === Status.Success) {
                dispatch({
                    type: AUTH_TYPES.RESEND_SUCCESS,
                    payload: {...response},
                });
            } else {
                dispatch({
                    type: AUTH_TYPES.RESEND_FAILURE,
                    payload: {...response},
                });
            }
        } catch (e: any) {
            dispatch({
                type: AUTH_TYPES.RESEND_FAILURE,
                error: 'Something went wrong',
            });
        }
    };
export const logout: logoutFunc = (rest: RestAPI): ThunkAction<void, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch({
                type: AUTH_TYPES.LOGOUT_REQUEST,
            });
            const response = await rest.logout();
            if (response.status === Status.Success) {
                dispatch(setToken(null))
                dispatch({
                    type: AUTH_TYPES.LOGOUT_SUCCESS,
                    payload: {...response},
                });
            } else {
                dispatch({
                    type: AUTH_TYPES.LOGOUT_FAILURE,
                    payload: {...response},
                });
            }
        } catch (e: any) {
            dispatch({
                type: AUTH_TYPES.LOGOUT_FAILURE,
                error: 'Something went wrong',
            });
        }
    };


export const getProfile: getProfileFunc = (): ThunkAction<void, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            dispatch({
                type: AUTH_TYPES.CONFIRM_REQUEST,
            });
            const response = await clientApi.get('/auth/me', {withCredentials: true});
            if (response.data.status === Status.Success) {
                if (response.data.user) {
                    dispatch(setUserData(response.data.user));
                }
            }
        } catch (e: any) {
            console.log('something went wrong')
        }
    };

export const signInWithEmailCleanUp = () => (dispatch: any) => {
    dispatch({
        type: AUTH_TYPES.SIGN_IN_CLEAN_UP,
    });
};

export const signUpWithEmailCleanUp = () => (dispatch: any) => {
    dispatch({
        type: AUTH_TYPES.SIGN_UP_CLEAN_UP,
    });
};
export const confirmCleanUp = () => (dispatch: any) => {
    dispatch({
        type: AUTH_TYPES.CONFIRM_CLEAN_UP,
    });
};
