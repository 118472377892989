import {RestAPI} from 'pkg/tulula/auth/api';

export const AUTH_TYPES = {
    SIGN_IN_REQUEST: 'AUTH_TYPES.SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'AUTH_TYPES.SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'AUTH_TYPES.SIGN_IN_FAILURE',
    SIGN_IN_CLEAN_UP: 'AUTH_TYPES.SIGN_IN_CLEAN_UP', // remove errors and other data from the storage

    SIGN_UP_REQUEST: 'AUTH_TYPES.SIGN_UP_REQUEST',
    SIGN_UP_SUCCESS: 'AUTH_TYPES.SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'AUTH_TYPES.SIGN_UP_FAILURE',
    SIGN_UP_CLEAN_UP: 'AUTH_TYPES.SIGN_UP_CLEAN_UP', // remove errors and other data from the storage

    CONFIRM_REQUEST: 'AUTH_TYPES.CONFIRM_REQUEST',
    CONFIRM_SUCCESS: 'AUTH_TYPES.CONFIRM_SUCCESS',
    CONFIRM_FAILURE: 'AUTH_TYPES.CONFIRM_FAILURE',
    CONFIRM_CLEAN_UP: 'AUTH_TYPES.CONFIRM_CLEAN_UP', // remove errors and other data from the storage

    LOGOUT_REQUEST: 'AUTH_TYPES.LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH_TYPES.LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH_TYPES.LOGOUT_FAILURE',
    LOGOUT_CLEAN_UP: 'AUTH_TYPES.LOGOUT_CLEAN_UP', // remove errors and other data from the storage

    RESEND_REQUEST: 'AUTH_TYPES.RESEND_REQUEST',
    RESEND_SUCCESS: 'AUTH_TYPES.RESEND_SUCCESS',
    RESEND_FAILURE: 'AUTH_TYPES.RESEND_FAILURE',
    RESEND_CLEAN_UP: 'AUTH_TYPES.RESEND_CLEAN_UP', // remove errors and other data from the storage
};

export type signInWithEmailFunc = (rest: RestAPI, email: string, password: string, rememberMe: boolean) => void;

export type signUpWithEmailFunc = (
    rest: RestAPI,
    email: string,
    password: string,
    name: string,
    surname: string,
    teamID?: string
) => void;


export type confirmEmailFunc = (
    rest: RestAPI,
    code: string
) => void

export type resendFunc = (
    email: string,
    rest: RestAPI,
) => void
export type logoutFunc = (
    rest: RestAPI,
) => void

export type getProfileFunc = () => void