export const GAME_PAGE_TYPES = {
  JOIN_CONTEST_REQUEST: 'GAME_PAGE_TYPES.JOIN_CONTEST_REQUEST',
  JOIN_CONTEST_SUCCESS: 'GAME_PAGE_TYPES.JOIN_CONTEST_SUCCESS',
  JOIN_CONTEST_FAIL: 'GAME_PAGE_TYPES.JOIN_CONTEST_FAIL',

  GET_NOMINATIONS_REQUEST: 'GAME_PAGE_TYPES.GET_NOMINATIONS_REQUEST',
  GET_NOMINATIONS_SUCCESS: 'GAME_PAGE_TYPES.GET_NOMINATIONS_SUCCESS',
  GET_NOMINATIONS_FAIL: 'GAME_PAGE_TYPES.GET_NOMINATIONS_FAIL',

  GET_TASKS_REQUEST: 'GAME_PAGE_TYPES.GET_TASKS_REQUEST',
  GET_TASKS_SUCCESS: 'GAME_PAGE_TYPES.GET_TASKS_SUCCESS',
  GET_TASKS_FAIL: 'GAME_PAGE_TYPES.GET_TASKS_FAIL',

  GET_TASKS_MUSTHAVE_REQUEST: 'GAME_PAGE_TYPES.GET_TASKS_MUSTHAVE_REQUEST',
  GET_TASKS_MUSTHAVE_SUCCESS: 'GAME_PAGE_TYPES.GET_TASKS_MUSTHAVE_SUCCESS',
  GET_TASKS_MUSTHAVE_FAIL: 'GAME_PAGE_TYPES.GET_TASKS_MUSTHAVE_FAIL',

  GET_TASK_REQUEST: 'GAME_PAGE_TYPES.GET_TASK_REQUEST',
  GET_TASK_SUCCESS: 'GAME_PAGE_TYPES.GET_TASK_SUCCESS',
  GET_TASK_FAIL: 'GAME_PAGE_TYPES.GET_TASK_FAIL',

  GET_RANDOM_TASK_REQUEST: 'GAME_PAGE_TYPES.GET_RANDOM_TASK_REQUEST',
  GET_RANDOM_TASK_SUCCESS: 'GAME_PAGE_TYPES.GET_RANDOM_TASK_SUCCESS',
  GET_RANDOM_TASK_FAIL: 'GAME_PAGE_TYPES.GET_RANDOM_TASK_FAIL',

  SEND_ANSWER_REQUEST: 'GAME_PAGE_TYPES.SEND_ANSWER_REQUEST',
  SEND_ANSWER_SUCCESS: 'GAME_PAGE_TYPES.SEND_ANSWER_SUCCESS',
  SEND_ANSWER_FAIL: 'GAME_PAGE_TYPES.SEND_ANSWER_FAIL',

  SEND_REANSWER_REQUEST: 'GAME_PAGE_TYPES.SEND_REANSWER_REQUEST',
  SEND_REANSWER_SUCCESS: 'GAME_PAGE_TYPES.SEND_REANSWER_SUCCESS',
  SEND_REANSWER_FAIL: 'GAME_PAGE_TYPES.SEND_REANSWER_FAIL',

  SET_FORM_STATE: 'GAME_PAGE_TYPES.SET_FORM_STATE',
};
