import React from 'react';
import {SignIn} from './SignIn';
import {SignUp} from './SignUp';
import {Confirm} from "./Confirm";

type AuthState = 'sign-in' | 'sign-up' | 'confirm';

export interface ILoginModal {
    state: AuthState;
    showEmailForm?: boolean;
    onLogout?: void;
}

export const LoginModal: React.FC<ILoginModal> = ({
                                                      state = 'sign-in',
                                                      showEmailForm = false,
                                                      onLogout
                                                  }: ILoginModal) => {
    const [authState, setAuthState] = React.useState<AuthState>(state);
    const [useEmail, setUseEmail] = React.useState(showEmailForm);

    const useSignInCallback = React.useCallback(() => {
        setAuthState('sign-in');
    }, [setAuthState]);

    const useSignUpCallback = React.useCallback(() => {
        setAuthState('sign-up');
    }, [setAuthState]);

    const useConfirmCallback = React.useCallback(() => {
        setAuthState('confirm');
    }, [setAuthState]);

    return (
        <>
            {authState === 'sign-in' && (
                <SignIn key={'sign-in'} showConfirmForm={useConfirmCallback} showSignUpForm={useSignUpCallback}
                        showEmailForm={useEmail}
                        onUseEmail={setUseEmail}/>
            )}
            {authState === 'sign-up' && (
                <SignUp key={'sign-up'} showConfirmForm={useConfirmCallback} showSignInForm={useSignInCallback}
                        showEmailForm={useEmail}
                        onUseEmail={setUseEmail}/>
            )}
            {authState === 'confirm' && (
                <Confirm onLogout={useSignUpCallback} key={'confirm'} showConfirmForm={useConfirmCallback}/>
            )}
        </>
    );
};
