import React from 'react';
import styles from './style.module.scss';

const CascadeImg = () => {
    return (
        <div className={styles.cascadeImg}>
            <img src='/assets/icons/buildings.svg' alt='Buildings'/>
            <div className={styles.horizontalRow}></div>
        </div>
    );
};

export default CascadeImg;
