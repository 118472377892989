import Button from '../Button/Button';
import {OAuthFacebook, OAuthGoogle, OAuthVK} from "./constants";
import {SignUpErrors} from './SignUpErrors';
import React, {useCallback} from 'react';
import {FaFacebookSquare} from 'react-icons/fa';
import {FcGoogle} from 'react-icons/fc';
import styles from './style.module.scss';
import Divider from '../Divider/Divider';
import {IErrors} from "src/helpers/interfaces";

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;
const APP_URL = process.env.REACT_APP_URL;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

interface IProps {
    useEmail: boolean;
    onUseEmail: () => void;
    onUseSignIn: () => void;
    email: string;
    onChangeEmail: (email: string) => void;
    password: string;
    onChangePassword: (password: string) => void;
    name: string;
    surname: string;
    onChangeName: (name: string) => void;
    onChangeSurname: (surname: string) => void;
    onSubmit: () => void;
    loading: boolean;
    errors: IErrors;
    onBlur: () => void;
}

export const SignUpForm = ({
                               useEmail,
                               onUseEmail,
                               onUseSignIn,
                               email,
                               name,
                               surname,
                               password,
                               onChangePassword,
                               onChangeEmail,
                               onChangeName,
                               onChangeSurname,
                               onSubmit,
                               onBlur,
                               loading = false,
                               errors,
                           }: IProps) => {
    const isLoginActive = !loading && email !== '' && password !== '' && name !== '';

    const submitCallback = useCallback<React.FormEventHandler<HTMLAnchorElement | HTMLFormElement>>(
        e => {
            e.preventDefault();
            onSubmit();
        },
        [onSubmit]
    );

    // quick hack for swamp up.
    const teamSlug = 'dee-vee-00002x';
    const back = `${LOGIN_URL}${APP_URL}`

    const oauthParams: string = [
       /* "app=chat",
        `team=${teamSlug}`*/
        `redirect_to=${back}`,
    ]
        .filter(Boolean)
        .join("&");

    const googleAddr = `${AUTH_API_URL}${OAuthGoogle}?${oauthParams}`
    const vkAddr = `${AUTH_API_URL}${OAuthVK}?${oauthParams}`


    return (
        <div className={styles.authWrapper}>
            <img src='/assets/icons/key.svg' alt='Key'/>
            <h3>Добро пожаловать</h3>

            <div className={styles.OAuth2}>
                <a
                    className={styles.linkLikesBtn}
                    href={googleAddr}>
                    Войти через Google
                </a>
                <a
                    className={styles.linkLikesBtn}
                    href={vkAddr}>
                    Войти через VK
                </a>
            </div>
            <Divider text={'или'}/>

            {useEmail ? (
                <form onSubmit={submitCallback}>
                    <SignUpErrors errors={errors} onUseSignIn={onUseSignIn}/>

                    <input
                        type='email'
                        name='email'
                        autoComplete="email"
                        placeholder='Email'
                        onBlur={onBlur}
                        autoFocus={true}
                        value={email}
                        onChange={event => onChangeEmail(event.target.value)}
                    />

                    {errors['email'] ? (
                        <div className={styles.error} key={`error-email`}>{errors['email']}</div>
                    ) : null}
                    <input
                        type='text'
                        name='name'
                        autoComplete="name"
                        placeholder='Имя'
                        onBlur={onBlur}
                        value={name}
                        onChange={event => onChangeName(event.target.value)}

                    />
                    {errors['name'] ? (
                        <div className={styles.error} key={`error-name`}>{errors['name']}</div>
                    ) : null}
                    <input
                        type='text'
                        name='surname'
                        autoComplete="surname"
                        placeholder='Фамилия'
                        onBlur={onBlur}
                        value={surname}
                        onChange={event => onChangeSurname(event.target.value)}

                    />
                    {errors['surname'] ? (
                        <div className={styles.error} key={`error-surname`}>{errors['surname']}</div>
                    ) : null}

                    <input
                        type='password'
                        name='password'
                        autoComplete="new-password"
                        placeholder='Пароль'
                        onBlur={onBlur}
                        value={password}
                        onChange={event => onChangePassword(event.target.value)}

                    />
                    {errors['password'] ? (
                        <div className={styles.error} key={`error-password`}>{errors['password']}</div>
                    ) : null}
                    <Button type={'submit'} variant={'primary'} disabled={!isLoginActive}>Зарегистрироваться</Button>
                    <input type="submit" hidden={true}/>

                </form>
            ) : (
                <>
                    <a className={styles.linkLikesBtn} onClick={onUseEmail}>Зарегистрироваться через Codenrock</a>
                </>
            )}
            <div className={styles.switcher}>
                <a className={styles.linkColored} onClick={(event) => {
                    event.preventDefault()
                    onUseSignIn()
                }}>Уже есть аккаунт</a>
            </div>
        </div>
    );
};
