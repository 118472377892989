import { connect } from 'react-redux';
import { IRootState } from '../../store/store';
import { GamePage as Self } from './GamePage';
import { getContests } from "../../actions/user/User.actions";
import { joinContest, getNominations, getTasksMustHave, getTask, getRandomTask, setFormState } from '../../actions/gamePage/GamePage.actions';
import { getForm } from '../../actions/formPage/FormPage.actions';
import {getProfile} from "../../actions/auth/auth.actions";

const mapStateToProps = ({ user, gamePage }: IRootState) => ({
  token: user.token,
  userData: user.data,
  getContestsError: user.getContestsError,
  getContestsData: user.getContestsData,
  joinContestError: gamePage.joinContestError,
  getNominationsData: gamePage.getNominationsData,
  getNominationsError: gamePage.getNominationsError,
  getTasksMustHaveData: gamePage.getTasksMustHaveData,
  formState: gamePage.formState,
});

const mapDispatchToProps = {
  getProfile,
  getContests,
  joinContest,
  getNominations,
  getTasksMustHave,
  getTask,
  getRandomTask,
  setFormState,
  getForm,
};

const GamePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);

export default GamePage;
