import {confirmCleanUp, confirmEmail, resend, logout} from '../../actions/auth/auth.actions';
import {UseRestAPI} from '../../pkg/tulula/auth/context';
import React from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {IRootState} from '../../store/store';
import {IErrors} from "src/helpers/interfaces";
import {ConfirmForm} from "./ConfirmForm";
import {confirmEmailFunc, logoutFunc, resendFunc} from "src/actions/auth/auth.types";
import {PATH_GAME, PATH_HOME, PATH_RULES} from '../../helpers/urlList';
import {IUserData} from "src/reducers/User.reducer";

interface IProps {
    confirmLoading: boolean;
    resending: boolean;
    userToken: string | null;
    userData: IUserData | null;
    confirmEmail: confirmEmailFunc;
    logout: logoutFunc;
    resend: resendFunc;
    errors: IErrors;
    showConfirmForm: () => void;
    onLogout?: () => void;
}

export const ConfirmComponent = ({
                                     resending,
                                     userToken,
                                     confirmLoading,
                                     logout,
                                     resend,
                                     confirmEmail,
                                     userData,
                                     errors,
                                     onLogout
                                 }: IProps) => {
    const [code, setCode] = React.useState('');
    //const [resending, setResending] = React.useState(false);

    // errors are set if fields are invalid
    const [validationErrors, setValidationErrors] = React.useState<IErrors>({});
    // submitted is a marker that user clicked submit button
    // some errors (required fields) are shown only when user clicked submit
    const [submitted, setSubmitted] = React.useState<boolean>(false);

    const authAPI = UseRestAPI();
    const navigate = useNavigate();
    const email = localStorage.getItem('email');
    // clean errors when unmount
    React.useEffect(() => {
        return () => {
            confirmCleanUp();
        };
    }, [confirmCleanUp]);

    React.useEffect(() => {
        // update validation errors with errors from the response
        setValidationErrors(errors);
    }, [errors]);

    const submitCallback = React.useCallback(() => {
        setSubmitted(true);
        confirmEmail(authAPI, code);
    }, [code, confirmEmail, authAPI]);


    const resendCallback = React.useCallback(() => {
        resend(userData ? userData.email : email ? email : '', authAPI);
    }, [resend, authAPI]);

    const logoutCallback = React.useCallback(() => {
        logout(authAPI);
    }, [logout, authAPI]);

    React.useEffect(() => {
        //let token = localStorage.getItem("auth_tok")
        // if we get user token -> close the modal and redirect to rubric

        if (!userToken && onLogout) {
            onLogout();
        }
    }, [userToken]);

    React.useEffect(() => {
        // if we get user token -> close the modal and redirect to rubric
        if (userData) {
            if (userData.confirmed) {
                navigate(PATH_RULES);
            }
        }
    }, [navigate, userData]);

    return (
        <ConfirmForm
            email={userData ? userData.email : email}
            resending={resending}
            userData={userData}
            code={code}
            onChangeCode={setCode}
            onSubmit={submitCallback}
            resendHandler={resendCallback}
            logoutHandler={logoutCallback}
            loading={confirmLoading}
            errors={validationErrors}
        />
    );
};

const mapStateToProps = ({auth, user}: IRootState) => (
    {
    resending: auth.resending,
    confirmLoading: auth.confirmLoading,
    errors: auth.errors,
    userData: user.data,
    userToken: user.token,
});

const mapDispatchToProps = {
    logout,
    resend,
    confirmEmail,
    confirmCleanUp,
};

export const Confirm = connect(mapStateToProps, mapDispatchToProps)(ConfirmComponent);
