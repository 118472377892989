import {setToken, setUserData} from "../../actions/user/User.actions";
import {connect} from 'react-redux';
import {HomePage as Self} from './HomePage';
import {IRootState} from '../../store/store';

const mapStateToProps = ({user}: IRootState) => ({
    token: user.token,
    user: user.data,
});

const mapDispatchToProps = {
    setToken,
    setUserData,
};

const HomePage = connect(mapStateToProps, mapDispatchToProps)(Self);

export default HomePage;
