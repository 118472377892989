import { connect } from 'react-redux';
import { LeaderboardDtPage as Self } from './LeaderboardDtPage';
import { getRatingItems } from '../../actions/leaderboardPage/leaderboardPage.actions';
import { IRootState } from '../../store/store';

const mapStateToProps = ({ leaderboardPage }: IRootState) => ({
  getLeaderboardItemsData: leaderboardPage.getLeaderboardItemsData,
  getLeaderboardItemsLoading: leaderboardPage.getLeaderboardItemsLoading,
  currentPage: leaderboardPage.currentPage,
  pagesCount: leaderboardPage.pagesCount,
});

const mapDispatchToProps = {
  getRatingItems,
};

const LeaderboardDtPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Self);

export default LeaderboardDtPage;
