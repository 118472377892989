import Button from '../Button/Button';
import styles from './style.module.scss';
import React, {useCallback} from 'react';
import {IErrors} from "src/helpers/interfaces";
import {IUserData} from "src/reducers/User.reducer";

interface IProps {
    resending: boolean
    code: string
    email: string | null
    onChangeCode: (code: string) => void;
    onSubmit: () => void;
    resendHandler: () => void;
    logoutHandler: () => void;
    loading: boolean;
    errors: IErrors;
    userData: IUserData | null;
}

export const ConfirmForm = ({
                                resending,
                                code,
                                email,
                                onChangeCode,
                                userData,
                                onSubmit,
                                resendHandler,
                                logoutHandler,
                                loading = false,
                                errors,
                            }: IProps) => {
    const isActive = !loading && code !== '';

    const submitCallback = useCallback<React.FormEventHandler<HTMLAnchorElement | HTMLFormElement>>(
        e => {
            e.preventDefault();
            onSubmit();
        },
        [onSubmit]
    );
    return (
        <div className={styles.authWrapper}>
            <img src='/assets/icons/key.svg' alt='Key'/>
            <h3>Подтвердите почту</h3>
            <form onSubmit={submitCallback}>
                <input
                    className={styles.codeInput}
                    type='text'
                    name='code'
                    autoComplete="code"
                    placeholder='Код из email'
                    autoFocus={true}
                    value={code}
                    onChange={event => onChangeCode(event.target.value)}
                />
                {errors['code'] ? (
                    <div className={styles.error} key={`error-code`}>{errors['code']}</div>
                ) : null}
                {email ? <div className={styles.emailInfo}>
                    <div>Мы отправили письмо на {email}</div>
                    <div> Не пришло письмо? <a role={"button"} onClick={resendHandler}>Отправить письмо
                        повторно</a> или <a
                        role={"button"} onClick={logoutHandler}>выйти</a></div>
                    {resending ? <div className={styles.success}>Отправлено</div> : <></>}
                </div> : <></>}
                <Button type={'submit'} variant={'primary'} disabled={!isActive}>Подтвердить</Button>
                <input type="submit" hidden={true}/>
            </form>
        </div>
    );
};
