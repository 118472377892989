import {FaFacebookSquare} from 'react-icons/fa';
import {FcGoogle} from 'react-icons/fc';
import Button from '../Button/Button';
import Divider from '../Divider/Divider';
import styles from './style.module.scss';
import {OAuthFacebook, OAuthGoogle, OAuthVK} from "./constants";
import React, {useCallback} from 'react';
import {IErrors} from "src/helpers/interfaces";

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;
const APP_URL = process.env.REACT_APP_URL;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

interface IProps {
    useEmail: boolean;
    onUseEmail: () => void;
    onUseSignUp: () => void;
    email: string;
    onChangeEmail: (email: string) => void;
    password: string;
    onChangePassword: (password: string) => void;
    onSubmit: () => void;
    loading: boolean;
    errors: IErrors;
    onBlur: () => void;
}

export const SignInForm = ({
                               useEmail,
                               onUseEmail,
                               onUseSignUp,
                               email,
                               password,
                               onChangePassword,
                               onChangeEmail,
                               onSubmit,
                               onBlur,
                               loading = false,
                               errors,
                           }: IProps) => {
    const isLoginActive = !loading && email !== '' && password !== '';

    const submitCallback = useCallback<React.FormEventHandler<HTMLAnchorElement | HTMLFormElement>>(
        e => {
            e.preventDefault();
            onSubmit();
        },
        [onSubmit]
    );
    // quick hack for swamp up.
    const back = `${LOGIN_URL}${APP_URL}`

    const oauthParams: string = [
        `redirect_to=${back}`
    ]
        .filter(Boolean)
        .join("&");

    const googleAddr = `${AUTH_API_URL}${OAuthGoogle}?${oauthParams}`
    const vkAddr = `${AUTH_API_URL}${OAuthVK}?${oauthParams}`

    return (
        <div className={styles.authWrapper}>
            <img src='/assets/icons/key.svg' alt='Key'/>
            <h3>Добро пожаловать</h3>

            <div className={styles.OAuth2}>
                <a
                    className={styles.linkLikesBtn}
                    href={googleAddr}>
                    Войти через Google
                </a>
                <a
                    className={styles.linkLikesBtn}
                    href={vkAddr}>
                    Войти через VK
                </a>
            </div>
            <Divider text={'или'}/>
            {useEmail ? (
                <form onSubmit={submitCallback}>
                    <input
                        type='email'
                        name='email'
                        autoComplete="email"
                        placeholder='Email'
                        onBlur={onBlur}
                        autoFocus={true}
                        value={email}
                        onChange={event => onChangeEmail(event.target.value)}
                    />
                    {errors['email'] ? (
                        <div className={styles.error} key={`error-email`}>{errors['email']}</div>
                    ) : null}
                    <input
                        type='password'
                        name='password'
                        autoComplete="current-password"
                        placeholder='Пароль'
                        onBlur={onBlur}
                        value={password}
                        onChange={event => onChangePassword(event.target.value)}

                    />
                    {errors['password'] ? (
                        <div className={styles.error} key={`error-pass`}>{errors['password']}</div>
                    ) : null}
                    <Button type={'submit'} variant={'primary'} disabled={!isLoginActive}>Войти</Button>
                    <input type="submit" hidden={true}/>
                </form>
            ) : (
                <>
                    <a className={styles.linkLikesBtn} onClick={onUseEmail}>Войти через Codenrock</a>
                </>
            )}

            <div className={styles.switcher}>
                Нет аккаунта?&nbsp;
                <a className={styles.linkColored} onClick={(event) => {
                    event.preventDefault()
                    onUseSignUp()
                }}>Зарегистрироваться</a>
            </div>

        </div>
    );
};
