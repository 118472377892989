/*
 * Rest API is used for authorization flow only.
 * All the other logic should be implemented with graphql api.
 */

import {
    IConfirmForm,
    IPasswordResetCheckForm,
    IPasswordResetForm,
    IPasswordResetSubmitForm, IResendForm,
    IResponse,
    ISignInForm,
    ISignUpForm
} from "src/helpers/interfaces";

const defaultHost = 'https://codenrock.com';
const defaultPrefix = '/api/auth';

// Response statuses by rest api.
export enum Status {
    // Something went wrong, check error or errors fields of Response.
    Failure = 'failure',
    Success = 'success',
}

async function api<T>(url: string, init?: RequestInit): Promise<T> {
    const response = await fetch(url, init);
    if (!response.ok) {
        return response.json();
    }
    return response.json();
}

// isResponse is a typeguard for Response interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isResponse(arg: any): arg is IResponse {
    return arg.status !== undefined;
}

export class RestAPI {
    private readonly prefix: string;
    private readonly host: string;
    private readonly headers: Record<string, string>;

    constructor(host: string = defaultHost, prefix: string = defaultPrefix, headers?: Record<string, string>) {
        this.host = host;
        this.prefix = prefix;
        const token = localStorage.getItem('auth_token');
        this.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            ...headers,
        };
    }

    // signUp user with email, name and password.
    // Registration is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    // User will be automatically signed in in case of success.
    public signUp(form: ISignUpForm): Promise<IResponse> {
        return this.post<IResponse>('register', {
            password_confirmation: form.password,
            privacy: 1,
            ...form,
        });
    }

    // signUp user with email, name and password.
    // Registration is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    // User will be automatically signed in in case of success.
    public confirm(form: IConfirmForm): Promise<IResponse> {
        return this.post<IResponse>('confirm-email', {
            ...form,
        });
    }

    // signIn user by email and password.
    // Authentication is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    public signIn(form: ISignInForm): Promise<IResponse> {
        return this.post<IResponse>('login', {
            email: form.email,
            password: form.password,
            ls: form.ls,
            lu: form.lu,
        });
    }

    // signIn user by email and password.
    // Authentication is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    public resendEmail(form: IResendForm): Promise<IResponse> {
        return this.post<IResponse>('resend-email', {
            email: form.email,
        });
    }

    // logout user
    public logout(): Promise<IResponse> {
        return this.post<IResponse>('logout');
    }

    // passwordReset send password reset email.
    // Request is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    public passwordReset(form: IPasswordResetForm): Promise<IResponse> {
        return this.post<IResponse>('password-reset', form);
    }

    // passwordResetCheck check password reset token.
    // Request is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    public passwordResetCheck(form: IPasswordResetCheckForm): Promise<IResponse> {
        return this.post<IResponse>('password-reset-check', form);
    }

    // passwordResetSubmit resets the password.
    // Request is successful if Response.status == Status.Success.
    // In all other cases please check Response.errors.
    public passwordResetSubmit(form: IPasswordResetSubmitForm): Promise<IResponse> {
        return this.post<IResponse>('password-reset-submit', form);
    }

    private api<T>(method: string, init?: RequestInit): Promise<T> {
        return api<T>(`${this.host}${this.prefix}/${method}`, {
            credentials: 'include',
            headers: this.headers,
            ...init,
        }).then(result => {
            if (isResponse(result)) {
                // Append error to errors[""] to simplify errors handling.
                if (result.errors === undefined) {
                    result.errors = {};
                }
                if (result.errors[''] === undefined) {
                    result.errors[''] = '';
                }
                //result.errors[''].push(result.errors);
            }
            return result;
        });
    }

    private post<T>(method: string, form?: unknown): Promise<T> {
        return this.api<T>(method, {
            method: 'post',
            body: form ? JSON.stringify(form) : undefined,
        });
    }
}
