import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { PATH_GAME, PATH_HOME } from '../../helpers/urlList';
import Button from '../../components/Button/Button';
import { Helmet } from "react-helmet-async";

const RulesPage = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const auth_token = localStorage.getItem('auth_token');
    const readRules = localStorage.getItem('read_rules');
    if (!readRules || readRules === 'false') {
      document.body.style.overflow = 'unset';
      localStorage.setItem('read_rules', 'true');
    }
    auth_token && setToken(auth_token);
  }, []);
  return (
    <MainLayout headerBtnTo={PATH_HOME} verticalAlign={'flex-start'} notContainerMt sx={{ paddingTop: '3.2rem' }}>
      <Helmet>
        <title>Rules - Job in the city</title>
      </Helmet>
      <div className={styles.pageWrapper}>
        <h1>Правила</h1>
        <div className={styles.description}>
        <p>Добро пожаловать в Job and the City!</p>
           <p>Ваша миссия состоит в том, чтобы найти все здания, выделенные серым цветом, 
            и ответить на вопросы. 
            Отвечайте правильно и быстро, чтобы оказаться на вершине лидерборда! За каждый правильный
             ответ вы получите 1 балл. Если вы столкнулись со сложностями, напишите нам на  
             <a href="mailto:support@codenrock.com"> support@codenrock.com</a>.</p>
           <p>Среди первой 30-ки лидеров будет проведен розыгрыш призов: самокаты от «Абсолют Банка» и
            Segway, балансборд и крутой мерч от «Альфа-Банка».</p>
           <p>Игра заканчивается 18 июня в 14:00 по московскому времени.</p>

           <p>Розыгрыш призов состоится 18 июня в 16:00 по московскому времени. Все участники игры
            получат ссылку на трансляцию розыгрыша призов после завершения игры.</p>
           <p>Удачи! 🍀</p>
        </div>
        {token && <Button variant={'primary'} sx={{ fontSize: '24px' }} href={PATH_GAME}>Далее</Button>}
      </div>
    </MainLayout>
  );
};

export default RulesPage;
