import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.scss'
import ObjectWrapperActive, {TObjectWrapper} from '../../components/ObjectWrapperActive/ObjectWrapperActive';
import ObjectWrapperDisabled, {
    TObjectWrapperDisabled
} from '../../components/ObjectWrapperDisabled/ObjectWrapperDisabled';
import Modal from '../../components/Modal/Modal';
import QuestionCard from '../../components/QuestionDialog';
import Header from '../../components/Header/Header';
import {IContest, IUserData} from "../../reducers/User.reducer";
import {setToken} from '../../actions/user/User.actions';
import {PATH_HOME, PATH_BOWLS, PATH_FORM} from '../../helpers/urlList';
import {INomination, ITask, ITaskList} from "../../reducers/GamePage.reducer";
import {IFormInfoData} from '../../reducers/FormPage.reducer';
import {Helmet} from "react-helmet-async";
import Plane from '../../components/Plane/Plane';
import {ReactComponent as AbsolutBank} from '../../assets/images/objects/absolutbank.svg';
import {ReactComponent as AlfaBank} from '../../assets/images/objects/alfabank.svg';
import {ReactComponent as Boat} from '../../assets/images/objects/boat.svg';
import {ReactComponent as Codenrock} from '../../assets/images/objects/codenrock.svg';
import {ReactComponent as GolfClub} from '../../assets/images/objects/golf.svg';
import {ReactComponent as PurpleBuilding} from '../../assets/images/objects/purple.svg';
import {ReactComponent as SegwayNinebot} from '../../assets/images/objects/segway-ninebot.svg';
import {ReactComponent as Skolkovo} from '../../assets/images/objects/skolkovo.svg';
import {ReactComponent as YellowBuilding} from '../../assets/images/objects/yellow.svg';

const CONTEST_ID = process.env.REACT_APP_CONTEST_ID;
const NUMBER_OF_QUESTIONS = 9;
const MUST_HAVE_NOMINATION_ID = 1000;
const FOR_2_BOWLS_NOMINATION_ID = 1001;

interface IGamePage {
    token: string | null;
    userData: IUserData | null;
    getProfile: () => void;
    getContests: () => void;
    getContestsError: string | null;
    getContestsData: IContest[] | null;
    joinContest: (contest_id: number, onSuccess?: () => void) => any;
    joinContestError: string | null;
    getNominations: (contest_id: number) => any;
    getNominationsData: INomination[] | null,
    getNominationsError: string | null,
    getTasksMustHave: (contest_id: number, nomination_id: number) => any;
    getTask: (contest_id: number, nomination_id: number, task_id: number, onSuccess?: () => void) => any;
    getRandomTask: (contest_id: number, nomination_id: number, onSuccess?: (task: ITask) => void) => any;
    setFormState: (state: boolean) => void;
    formState: boolean;
    getTasksMustHaveData: ITaskList[] | null;
    getForm: (onSuccess: (data: IFormInfoData) => void) => void;
}

export const GamePage: React.FC<IGamePage> = ({
                                                  token,
                                                  userData,
                                                  getProfile,
                                                  getContests,
                                                  getContestsError,
                                                  getContestsData,
                                                  joinContest,
                                                  joinContestError,
                                                  getNominations,
                                                  getNominationsData,
                                                  getNominationsError,
                                                  getTasksMustHave,
                                                  getTask,
                                                  getRandomTask,
                                                  getTasksMustHaveData,
                                                  setFormState,
                                                  getForm,
                                                  formState,
                                              }: IGamePage) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentNominationId, setCurrentNominationId] = useState<number>();
    const navigate = useNavigate();
    const contest_id = CONTEST_ID ? Number(CONTEST_ID) : -1;
    const [rightAnswer, setRightAnswer] = useState(false);
    const mustHaveNomination = getNominationsData ? getNominationsData[0] : undefined;
    const planeRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const getScore = (nominationsData: INomination[] | null) => {
        return nominationsData ?
            nominationsData.reduce((score, nomination) =>
                nomination.id === FOR_2_BOWLS_NOMINATION_ID && nomination.right_solutions_count > 0 ?
                    score + (nomination.right_solutions_count * 2) :
                    score + nomination.right_solutions_count, 0
            ) : 0;
    }

    useEffect(() => {
        const tokenLocal = localStorage.getItem('auth_token');
        if (!tokenLocal) {
            navigate(PATH_HOME);
        } else if (!userData) {
            getProfile();
        }

        if (getContestsData) {
            const contest = getContestsData.find(contest => contest.id === contest_id);
            if (contest) {
                getNominations(contest_id)
            }
        }
    }, []);
    useEffect(() => {
        if (userData) {
            if (!userData.confirmed) {
                navigate(PATH_HOME)
            } else if (!getContestsData) {
                getContests();
            }
        }

    }, [userData, joinContest]);

    useEffect(() => {
        if (getContestsData) {
            const contest = getContestsData.find(contest => contest.id === contest_id);
            if (contest) {
                if (!getNominationsData) {
                    getNominations(contest_id);
                }
            } else {
                joinContest(contest_id, () => getContests());
            }
        }
    }, [getContestsData, joinContest]);

    /* Check answer count for end game */
    useEffect(() => {
        const finishedGame = localStorage.getItem('finished_game');
        if (getNominationsData) {
            const countAnswer = getTasksMustHaveData ? getTasksMustHaveData.reduce((count, task) => count + (task.solutions.length > 0 ? 1 : 0), 0) : 0;
            if (countAnswer >= NUMBER_OF_QUESTIONS) {   // Check answer count for end game
                !finishedGame && navigate(PATH_BOWLS);
            } else { //Check right answer count for redirect to form
                if (getScore(getNominationsData) === 2 && formState) {
                    getForm((data) => {
                        setFormState(false);
                        if (!data.is_completed) {
                            navigate(PATH_FORM);
                        }
                    })
                }
            }
        }
    }, [getNominationsData, getTasksMustHaveData]);

    useEffect(() => {
        if (mustHaveNomination) {
            getTasksMustHave(mustHaveNomination.contest_id, mustHaveNomination.id);
            setIsLoading(false);
        }
    }, [mustHaveNomination]);


    // id:184 - What does TPI stand for?
    // id:187 - What is CML used for?
    // id:190 - Which of the following are a Feature of DVC?
    // id:191 - Which of the following is possible with DVC?
    // id:195 - What is the correct file to create a Docker image?
    // id:201 - Why is Ramen so important to Iterative?
    // id:203 - !!!What is the DeeVee back story?
    // id:205 - What is the best way to learn about Iterative Tools?
    // id:207 - How do you pronounce MLEM?
    // id:208 - What does MLEM help with?
    // id:210 - What can you build with GTO?
    // id:213 - ___ are a special kind of plots generated by Studio to show how your metrics have been changing over the course of your ML experimentation.

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    const calcLeft = (x: number): string => (width <= 428) ? `calc(100vw*${x}/428)` : `${x}px`;
    const calcTop = (y: number): string => (width <= 428) ? `calc((100vw*1096/428*${y})/1096)` : `${y}px`;
    const calcWidth = (w: number): string => (width <= 428) ? `calc(100vw/428 * ${w})` : `${w}px`;
    const calcHeight = (h: number): string => (width <= 428) ? `calc(100vw/428 * ${h})` : `${h}px`;

    const objectActiveConfigs: TObjectWrapper[] = [
        {
            key: 1,
            src: <AbsolutBank/>,
            alt: 'AbsolutBank',
            left: calcLeft(300),
            top: calcTop(210),
            width: calcWidth(115),
            height: calcHeight(160),
            task_id: 2424,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2424, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 2,
            src: <AlfaBank/>,
            alt: 'AlfaBank',
            left: calcLeft(140),
            top: calcTop(70),
            width: calcWidth(87),
            height: calcHeight(251),
            task_id: 2425,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2425, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 3,
            src: <Codenrock/>,
            alt: 'Codenrock',
            left: calcLeft(180),
            top: calcTop(175),
            width: calcWidth(123),
            height: calcHeight(300),
            task_id: 2426,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2426, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 4,
            src: <SegwayNinebot/>,
            alt: 'SegwayNinebot',
            left: calcLeft(-10),
            top: calcTop(175),
            width: calcWidth(117),
            height: calcHeight(229),
            task_id: 2427,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2427, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 9,
            src: <Skolkovo/>,
            alt: 'Skolkovo',
            left: calcLeft(330),
            top: calcTop(325),
            width: calcWidth(85),
            height: calcHeight(294),
            nomination_id: MUST_HAVE_NOMINATION_ID,
            task_id: 2428,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2428, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 7,
            src: <PurpleBuilding/>,
            alt: 'PurpleBuilding',
            left: calcLeft(175),
            top: calcTop(415),
            width: calcWidth(160),
            height: calcHeight(319),
            task_id: 2429,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2429, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 8,
            src: <YellowBuilding/>,
            alt: 'YellowBuilding',
            left: calcLeft(10),
            top: calcTop(310),
            width: calcWidth(154),
            height: calcHeight(276),
            task_id: 2430,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2430, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 10,
            src: <GolfClub/>,
            alt: 'GolfClub',
            left: calcLeft(10),
            top: calcTop(700),
            width: calcWidth(96),
            height: calcHeight(72),
            nomination_id: MUST_HAVE_NOMINATION_ID,
            task_id: 2431,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2431, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
        {
            key: 11,
            src: <Boat/>,
            alt: 'Boat',
            left: calcLeft(50),
            top: calcTop(995),
            width: calcWidth(77),
            height: calcHeight(80),
            task_id: 2432,
            onClick: () => {
                getTask(contest_id, MUST_HAVE_NOMINATION_ID, 2432, () => {
                    setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
                    setIsModal(true)
                })
            }
        },
    ]

    const isActiveMustHave = (task_id: number, tasksData: ITaskList[]) => {
        const task = tasksData.find(task => task.id === task_id);
        return task && task.solutions.length > 0;
    }

    const isActiveRandom = (nomination_id: number, nominationsData: INomination[]) => {
        const nomination = nominationsData.find(nomination => nomination.id === nomination_id);
        return nomination && nomination.solutions_count > 0;
    }

    const getDynamicBuilds = (
        objectConfigs: TObjectWrapper[],
        getTasksMustHaveData: ITaskList[] | null,
        getNominationsData: INomination[] | null
    ) => {
        return objectConfigs.map(item => {
            if (getTasksMustHaveData && item.task_id) {
                if (isActiveMustHave(item.task_id, getTasksMustHaveData)) {
                    return <ObjectWrapperDisabled key={item.key} src={item.src} alt={item.alt} top={item.top}
                                                  left={item.left} bottom={item.bottom} zIndex={item.zIndex}
                                                  width={item.width} height={item.height}/>
                } else {
                    return <ObjectWrapperActive key={item.key} src={item.src} alt={item.alt} top={item.top}
                                                left={item.left} right={item.right} bottom={item.bottom}
                                                onClick={item.onClick} zIndex={item.zIndex} width={item.width}
                                                height={item.height}/>
                }
            } else if (getNominationsData && item.nomination_id) {
                if (isActiveRandom(item.nomination_id, getNominationsData)) {
                    return <ObjectWrapperDisabled key={item.key} src={item.src} alt={item.alt} top={item.top}
                                                  left={item.left} bottom={item.bottom} zIndex={item.zIndex}
                                                  width={item.width} height={item.height}/>
                } else {
                    return <ObjectWrapperActive key={item.key} src={item.src} alt={item.alt} top={item.top}
                                                left={item.left} right={item.right} bottom={item.bottom}
                                                onClick={item.onClick} zIndex={item.zIndex} width={item.width}
                                                height={item.height}/>
                }
            } else return <ObjectWrapperActive key={item.key} src={item.src} alt={item.alt} top={item.top}
                                               left={item.left} bottom={item.bottom} zIndex={item.zIndex}
                                               width={item.width} height={item.height}/>
        })
    }

    useEffect(() => {
        if (rightAnswer && !isModal) {
            planeRef.current.style.setProperty('top', `${window.scrollY + (window.innerHeight / 2) + 210}px`)
            setTimeout(() => setRightAnswer(false), 10000);
        }
    }, [!isModal, rightAnswer])

    return (
        <>
            <Helmet>
                <title>Game - Job in the city</title>
            </Helmet>
            <div className={styles.gameLayout}>
                {isLoading ? <div className={styles.preloader}></div> : <></>}
                <div className={styles.background}>
                    <Header rightCount={getScore(getNominationsData)}/>
                    {
                        getDynamicBuilds(objectActiveConfigs, getTasksMustHaveData, getNominationsData)
                    }
                    {rightAnswer && !isModal &&
                        <div className={styles.plane} ref={planeRef}>
                            <Plane/>
                        </div>
                    }
                    <Modal show={isModal} setShow={setIsModal}>
                        <QuestionCard nomination_id={currentNominationId} onClose={setIsModal}
                                      isRightAnswer={rightAnswer} setIsRightAnswer={setRightAnswer}/>
                    </Modal>
                </div>
            </div>
        </>
    );
};

const Game = React.memo(GamePage);
